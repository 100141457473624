import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import Main from "./containers/Main";
import Intro from "./components/Intro";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="home" element={<Main />} />
        <Route path="*" element={<Intro />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
