import React, { useEffect, useRef } from "react";
import "../styles/Footer.css";
import gsap, { Power2 } from "gsap";

const Footer = () => {
  const footerRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      footerRef,
      {
        opacity: 0,
        y: 500,
      },
      {
        opacity: 1,
        duration: 1,
        y: 0,
        scrollTrigger: { trigger: footerRef.current },
      }
    );
    gsap.utils.toArray(footerRef.current.children).forEach((curr, index) =>
      gsap.fromTo(
        curr,
        { opacity: 0, y: 500 },
        {
          scrollTrigger: { trigger: footerRef.current },
          opacity: 1,
          duration: 0.75,
          y: 0,
          delay: index * 0.075,
          ease: Power2.easeInOut,
        }
      )
    );
  }, []);
  return (
    <footer ref={footerRef}>
      <div className={"col col4"}>
        <h3>Contact</h3>
        <ul className={"footerList"}>
          <li>
            <a href="https://www.instagram.com/dangerouslyfruityart/">
              INSTAGRAM
            </a>
          </li>
          <li>
            <a href="mailto:louise.langer.art@gmail.com">EMAIL</a>
          </li>
        </ul>
        <h3 className={"addOn"}>:D</h3>
      </div>
    </footer>
  );
};

export default Footer;
