import React, { useState, useCallback, useEffect } from "react";
import gsap, { Expo } from "gsap";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../assets/images";
import "../styles/Main.css";
import Footer from "../components/Footer";

function Main() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    gsap
      .fromTo(
        ".wrapper",
        { opacity: 0 },
        { opacity: 1, duration: 2, ease: Expo.easeInOut }
      )
      .delay(0.7);
  }, []);

  return (
    <div className={"wrapper"}>
      <h1>Louise Langer</h1>
      <h2>Gallerie</h2>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <Footer />
    </div>
  );
}
export default Main;
