export const photos = [
  {
    src: "https://i.ibb.co/jJcgP4f/20.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/QNpgMKR/17.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/LZMFSvV/19.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/nrKtMvh/15.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/rvHgnYQ/13.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/171cDvz/9.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/S0CzxxR/12.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/F6CfD3v/1.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/sJwN2Lg/8.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/zHdRMJm/1.jpg",
    width: 4,
    height: 3,
  },
];
